import React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"

import SEO from "../components/SEO"
import Layout from "../components/layout/Layout"
import styles from "../css/contact-css-modules.module.css"
import Banner from "../components/Banner"
import Form from "../components/Form"
import { 
    Row, 
    ResponsiveRow, 
    Col, 
    SectionWrapper,
    SectionWrapperGray, 
    SectionInnerWrapper 
} from "../components/Wrapper"

const ContactPage = ({ data }) => {
    return (
        <Layout>
            <SEO
                title={`All Ins Agency - Contact Us`}
                description={`All Ins Agency`}
            />
            <Banner heading={data.allStrapiBanner.edges[0].node.heading} subHeading={data.allStrapiBanner.edges[0].node.subheading} trail={data.allStrapiBanner.edges[0].node.name} trailLink="/contact" image={data.site.siteMetadata.apiUrl + data.allStrapiBanner.edges[0].node.fields.imageUrl} />
            <SectionWrapper>
                <SectionInnerWrapper>
                    <ResponsiveRow alignTop={true}>
                        <Col>
                            <h4 className={styles.heading}>Customer Service</h4>
                            <Row alignTop={true} className={styles.contentWrapper}>
                                <div className={styles.iconWrapper}>
                                    <FontAwesomeIcon icon={faPhoneAlt} size="sm"></FontAwesomeIcon>
                                </div>
                                <div className={styles.contentLarge}>{data.allStrapiContact.edges[0].node.phone}</div>
                            </Row>
                            <Row alignTop={true}>
                                <div className={styles.iconWrapper}>
                                    <FontAwesomeIcon icon={faEnvelope} size="sm"></FontAwesomeIcon>
                                </div>
                                <div className={styles.contentSmall}>
                                    <div className={styles.contentLarge}>{data.allStrapiContact.edges[0].node.email}</div>
                                    <p>Operating Hours ({data.allStrapiContact.edges[0].node.operatingDays})</p>
                                    <div>Customer Service: {data.allStrapiContact.edges[0].node.customerServiceHours}</div>
                                    <div>Closed on {data.allStrapiContact.edges[0].node.closedDays}</div>
                                    <div className="mt-8 md:mt-12">
                                        <p>Visit us at:</p>
                                        <div>{data.allStrapiContact.edges[0].node.address1}</div>
                                        <div>{data.allStrapiContact.edges[0].node.address2}</div>
                                        <div>{data.allStrapiContact.edges[0].node.postalCode}</div>
                                    </div>
                                </div>
                            </Row>
                        </Col>
                        <Col className="mt-6 md:mt-0 md:ml-8">
                            <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=22%20Sin%20Ming%20Lane%20%2305-78%20Midview%20City%20Singapore%20573969&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                        </Col>
                    </ResponsiveRow>
                </SectionInnerWrapper>
            </SectionWrapper>
            <SectionWrapperGray>
                <SectionInnerWrapper>
                    <h4 className={styles.heading}>Contact Us</h4>
                    <div className="md:mx-4">
                        <Form
                            inputs={{
                                name: true, 
                                email: true,
                                phone: true,
                                message: true
                            }}
                        />
                    </div>
                </SectionInnerWrapper>
            </SectionWrapperGray>
        </Layout >
    )
}

export default ContactPage

export const contactQuery = graphql`
    query ContactQuery {
        allStrapiBanner(filter: {page: {eq: "/contact"}}, sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    subheading
                    heading
                    name
                    page
                    fields {
                        imageUrl
                        sortindexnum
                    }
                }
            }
        }
        allStrapiContact {
            edges {
                node {
                    address1
                    address2
                    closedDays
                    customerServiceHours
                    email
                    operatingDays
                    phone
                    postalCode
                }
            }
        }
        site {
            siteMetadata { 
                apiUrl
            }
        }
    }
`